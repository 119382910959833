import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

const showConnect = function() {
  document.addEventListener('DOMContentLoaded', (event) => {
    const copyButton = document.getElementById('copy-link-button');
    const textToCopy = document.getElementById('copy-link-text').innerText;

    copyButton.addEventListener('click', () => {
      navigator.clipboard.writeText(textToCopy).then(() => {
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    });
  });
}

const viewConnect = function() {
  const selectSettings = {
    allowEmptyOption: true,
    plugins: ['remove_button', 'input_autogrow', 'clear_button']
  }
  new TomSelect('#title_id', selectSettings)
  new TomSelect('#mpaa_rating', selectSettings)
}

export default class extends Controller {
  connect() {
    const controllerAction = this.data.get('action')
    switch (controllerAction) {
      case 'show':
        showConnect()
        break
      case 'edit':
        viewConnect()
        break
      case 'new':
        viewConnect()
        break
      case 'index':
        break
    }
  }
}
